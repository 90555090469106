import React, { Component } from "react";
import Chart from "react-apexcharts";

// class StatsChart extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       options: {
//         chart: {
//           id: "basic-bar"
//         },
//         xaxis: {
//           categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
//         }
//       },
//       series: [
//         {
//           name: "series-1",
//           data: [30, 40, 45, 50, 49, 60, 70, 91]
//         }
//       ]
//     };
//   }

//   render() {
//     return (
//       <div className="app">
//         <div className="row">
//           <div className="mixed-chart">
//             <Chart
//               options={this.state.options}
//               series={this.state.series}
//               type="line"
//               height="190"
//             />
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default StatsChart;

// convert above class component to functional component

const StatsChart = ({ xaxis, yaxis }) => {
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={{
              chart: {
                id: "basic-bar",
                zoom: {
                  enabled: false // Disable zooming
                }
              },
              xaxis: {
                categories: xaxis
              }
            }}
            series={[
              {
                name: "series-1",
                data: yaxis
              }
            ]}
            type="line"
            height="190"
          />
        </div>
      </div>
    </div>
  );
};

export default StatsChart;
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// import CryptoJS from 'crypto-js';
// import { Buffer } from 'buffer';
import { decryptData } from './middleware';

const PrivateRoute = ({ children }) => {
    var [isAuthenticated, setIsAuthenticated] = useState()
    const location = useLocation()
    var [returnurl, setReturnUrl] = useState("")

    var organazaionUrls = [
        "/dashboard",
        "/profileSetting",
        "/clientList",
        "/historyList",
        "/applicant",
        "/applicant",
        "/suspenceuser",
        "/userinvoice",
    ]

    var userUrls = [
        "/individual/kyc",
        "/individual/document",
        "/individual/settings",
        "/individual/overview",
        "/individual/support"
    ]

    var unorthorizedUrls = [
        "/"
    ]

    useEffect(() => {
        handleFunc()
    }, [])

    const handleFunc = async () => {
        var currentpath = location.pathname
        // console.log(currentpath, "currentpath")
        var token = window.localStorage.getItem('Rikosta');
        var user = window.localStorage.getItem('userType');
        if (token && user) {
            if (organazaionUrls.includes(currentpath)) {
                var usertype = decryptData(user)
                if (usertype && usertype == 'organization' || usertype == 'sub-admin') {
                    setIsAuthenticated(true)
                } else {
                    setIsAuthenticated(false)
                    setReturnUrl('/individual/kyc')
                }
            } else if (userUrls.includes(currentpath)) {
                var usertype = decryptData(user)
                if (usertype && usertype == 'individual') {
                    setIsAuthenticated(true)
                } else {
                    setIsAuthenticated(false)
                    // setReturnUrl('/dashboard')
                    setReturnUrl('/')
                }
            } else {
                setIsAuthenticated(false)
            }
        } else {
            setIsAuthenticated(false)
            setReturnUrl('/')
        }
    }
    return isAuthenticated && isAuthenticated == true ? children : isAuthenticated == false ? <Navigate to={returnurl} /> : "";
};

export default PrivateRoute;